.ContainerContact{



.MuiInput-underline{
    border-bottom: 2px solid transparent !important;
  }.MuiAutocomplete-input{
    // color:white !important;
    border-color: transparent !important;
}

.MuiInput-underline{
    border-bottom: 2px solid transparent !important;
  }

  .Choose::before{
      border: none !important;
  }

  .Choose::after{
    border: none !important;
}

.MuiInput-input{
    border: none !important;
}

}