.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.ContactNavabarContainer{
    display: flex;
    flex-direction: row;
    background-color: var(--color-gray);
    align-items: center;
    justify-content: space-around;
}

.ContactNavabarContainerInfo{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap:10px
}
.ConnectUSTitle{
    color:var(--color-white);
    font-size: 12px;
    margin: 0;
    cursor: pointer;
    margin-bottom: 4px;
    /* padding: 5px;  */
}
.ContactNavabarContainerInfoTitle{
    color:var(--color-white);
    font-size: 12px;
    margin: 0;
    padding: 5px;
}

.ContactNavabarContainerInfoIcon{
    color:var(--color-yellow);
    margin: 0;
}

.span{
    color:white;
}

.ConnectUS{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:7px
}

@media screen and (max-width:600px){
   .ConnectUSTitle, .ContactNavabarContainerInfoTitle{
        font-size: 9px;
    }
}

.ContactNavbarInfoContainer{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.ContactNavabarContainerWhatsappIcon{
    color: var(--color-whatsapp);
    cursor: pointer;
    margin-bottom: 4px;
}

