.Header{
    display: flex;
    padding: 1.2rem 0rem;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);
    border-bottom: 2px solid white;
   
}

.HeaderTitle{
    color: var(--color-white);
    font-weight: bold;
    font-size: 1.8rem;
}

.CareerHeader{
    display: flex;
    padding: 1.2rem 0rem;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);
    border-bottom: 2px solid white;
}

.Job{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem
}

.CareerHeaderTitle{
  
        color: var(--color-white);
        font-weight: bold;
        font-size: 1.8rem;
    
}

.container{
    padding: 0.5rem 3rem 3rem 3rem;
    margin: 0;
    background-color: var(--color-light-background);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.Heading{
    color:var(--color-footer);
    font-size: 1.5rem;
    font-weight:bold;
    padding: 1rem 0rem;
}

.HeadingTwo{
    color:var(--color-footer);
    font-size: 1.4rem;
    font-weight:bold;
    padding: 0.8rem 0rem;
}

.Para{
    font-size: 14px;
    color:var(--color-organization);
    letter-spacing: 1px;
}

.InputList{
    display: flex;
    flex-direction: column;
    gap:15px;
    width: 50%;
}



/* .resume{
    display: none;
} */

.ResumeUploadContainer{
    display: flex;
    
}

.Choose{
    display: none;

}

.UploadTitle{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.ResumeUpload{
    margin: 0;
    border: 2px solid #0066ff;
    border-radius: 3px;
    padding: 0.3rem 1.4rem;
    font-size: 14px;
    color: #0066ff;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.UploadContainer{
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    flex-direction: column;
}

.ButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FileNameContainer{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
}

.FileNameTitle{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    
}

.FileNameIconCancel{
    cursor: pointer;
}



.Card{
    
    border-radius: 3px;
     padding: 1.3rem 3rem;
     display: flex;
     flex-direction: column;
     gap:10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.Card:hover{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.JobTitle{
    font-size: 17px;
    font-weight: 600;
}

.ExpList{
    display: flex;
    flex-direction: row;
    gap:5px;
    align-items: center;
   
}

.Languages{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
}

.ExpListIcon{
    font-size: 18px;
    color: var(--color-organization);
}
.ExpListTitle{
    font-size: 14px;
    margin: 0;
    color: var(--color-organization);
}

.ExpContainer{
    display: flex;
    flex-direction: row;
    gap:15px;
    margin-top: -10px;

}

.LanguagesTitle{
    font-size: 14px;
    margin: 0;
    color: var(--color-organization);
}
.LanguagesTitleDot{
    display: flex;
    align-items: center;
    font-size: 14px;
    /* margin: 0; */
    color: var(--color-organization);
}

.JobDescription{
    font-size: 17px;
    font-weight: bold;
    margin:0;
}

.SmallHeading{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.ResponsibilitiesContainer{
    padding: 5px 5px;
}
.ResponsibilitiesUl{
    margin: 0;
    list-style-type: circle;
    font-size: 10px;
    padding: 0px 12px;
}

.Responsibilitiesli{
    padding: 2px 0px;
    font-size: 13px;
}

.EducationalText{
    padding: 2px 0px;
    font-size: 13px;
}

.RoleListContainer{
    padding: 15px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    gap:5px;
}

.RoleList{
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.RoleListTitle{
    min-width: 150px;
    margin: 0;
    color: var(--color-light-gray);
    font-size: 13px;

}

.RoleListText{
    min-width: 180px;
    margin: 0;
    font-size: 13px;
}

.SkillsContainer{
    padding: 10px 5px;
    max-width: 600px;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
     gap:10px;
   align-items: center;
}

.Skills{
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
   border: 1px solid gray;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50px;
   font-size: 12px;
   font-weight: 600;
   padding: 10px;
}



@media (min-width:300px) and (max-width:600px){
    .Card{
        cursor: pointer;
    }
    .JobDescription{
        font-size: 13px;
    }
    .JobTitle{
        font-size: 14px;
    }
    .SmallHeading{
        font-size: 13px;
    }
    .Responsibilitiesli{
        font-size: 12px;
    }
    .LanguagesTitle,.ExpListTitle{
        font-size: 11px; 
    }
    .ExpContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* gap:5px */
    }
    .RoleListTitle,.RoleListText{
        min-width: 120px;
       font-size: 12px;
    }

    .Skills{
        font-size: 12px;
    }
    
    .Card{
        padding: 1rem;
    }
    .CareerHeader{
        padding: 0.5rem 0rem;
    }
    .Heading{
        padding: 0.5rem 0rem;
        font-size: 1rem;
    }

    .HeadingTwo{
        font-size: 1rem;
        padding: 0.4rem 0rem;
    }
    .container{
        padding: 0.5rem 1rem;
    }

    .InputList{
        width: 100%;
    }
}

@media (min-width:601px) and (max-width:768px){
    .container{
        padding: 1rem 1.7rem;
    }

    .InputList{
        width: 80%;
    }

}

@media (min-width:768px) and (max-width:992px){
    .container{
        padding: 1rem 1.7rem;
    }

}

.ListContainer{
    display: flex;
    flex-direction: column;
}

.Error{
    margin: 0;
    font-size: 12px;
    color:#dc1d13;
    font-weight: 500;
}