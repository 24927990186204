.container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1.5rem 2rem;
    gap:10px;
}


.OneTitle {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--color-dark-blue);
      
}

.OneText {
    line-break: normal; 
    padding: 0rem 10rem;
    color: var(--color-gray);
    font-size: 16px;
}

.Two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 0 10rem;
}

.TwoList {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.IconContainer {
    background-color: var(--color-dark-blue);
    border-radius: 100%;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top:-15px;
}

.Icon {
    font-size: 28px;
    color: var(--color-yellow);
    /* padding: 1rem; */
    font-weight: bold;
    margin: 0;
}

.serviceContainer {
    display: flex;
    flex-direction: column;
}

.serviceContainerTitle {
    font-weight: bold;
    color: var(--color-dark-blue);
    font-size: 22px;
    margin: 0;
    padding: 5px 0px;
}

.serviceContainerText {
    font-size: 14px;
    line-break: normal;
    padding: 0rem 0rem;
    color: var(--color-gray);
    text-align: justify;
}
/* @media screen and (max-width:600px){
    .OneText{
        padding: 0rem 1rem;
    }

    .container{
        padding: 0;
        margin: 0;
    }
} */

@media (min-width:300px) and (max-width:600px){
     .OneTitle{
         font-size: 20px;
     }

     .OneText{
        font-size: 14px;
     }

     .IconContainer{
        padding : 0.8rem
     }

     .serviceContainerTitle{
         font-size: 14px;
     }

     .serviceContainerText{
        font-size: 12px;
     }
}

@media screen and (max-width:768px){
    .Two{
        grid-template-columns: 1fr;
        padding: 1rem;
    }
}

@media screen and (max-width:992px){
    .OneText{
        padding: 0rem 1rem;
    }
    
    .container{
        padding: 0;
    }
}

@media (min-width:768px) and (max-width:992px){

    .Two{
        grid-template-columns:1fr 1fr;
        padding: 1.2rem;
    }
}

@media (min-width:992px) and (max-width:1200px){
    .OneText{
        padding: 0rem 1rem;
    }
    .Two{
        grid-template-columns:1fr 1fr;
        padding: 1.2rem;
    }
}

@media (min-width:1200px) and (max-width:130px) {
     .Two{
         padding: 0 4rem;
     }
}