
:root{
    --font-base: 'proxima-nova', sans-serif;
    --font-title: 'Samarkan', sans-serif;
  
    --color-yellow:#f6ce4b;
    --color-light-blue:#54b2e2;
    --color-dark-blue:#002b80;
    --color-gray:#283c50;
    --color-light-gray:#808080;
    --color-light-gray-text:#737373;
    
    --color-white:#fff;
    --color-black:#000;
    --color-background:#fff;
    --color-light-background:#f6f6f6;
    --color-organization:#404040;

     --color-whatsapp: #25D366;
    --color-footer:#283c50;
  }
  
  
  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
    scroll-behavior: smooth;
  }
  
  
    body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  