.container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 3rem 2rem 1rem 2rem;
}

/* .ServiceHeader{
    background-color: var(--color-footer);
} */

.ServiceHeader{
    display: flex;
    padding: 1.2rem 0rem;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);
   
    border-bottom: 2px solid white;
}

.MidContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 10rem 1rem 10rem;
    gap:10px;
    
}

.MidCard{
    display: flex;
    flex-direction: column;
    /* border: 3px solid greenyellow; */

}
.MidCardIconContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    z-index: 5;
}
.RootIconContainer{
    border: 5px solid var(--color-footer);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    background-color: var(--color-white);
}

.RootIcon{
    font-size: 2.6rem;
    color: var(--color-footer);
    font-weight: bold;
}

.MuiIcon{
    font-size: 2.6rem;
    color: var(--color-footer);
    font-weight: bold;
}

.MidLastContainer{
    padding: 1rem 2rem 0.7rem 2rem;
    background-color: #ececec;
    min-height: 50vh;
}

.MidLastContainerTitle{
    padding: 1rem 1rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--color-footer);
    margin: 0;
    font-weight: bold;
    font-size: 20px;
}

.MidLastContainerText{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color:var(--color-organization);
    letter-spacing: 1px;
    text-align: justify;
    text-justify: inter-word;
    
}

.ServiceHeaderTitle{
    color: var(--color-white);
    font-weight: bold;
    font-size: 1.8rem;
}

.One{
    padding: 0rem 10rem;
}

.OneTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 24px;
    color: var(--color-dark-blue)
}


.OneText {
    line-break: normal;
    text-align: justify;
    color: var(--color-light-gray);
    font-size: 18px;
}

.Two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 1.4rem 10rem;
}

.TwoList {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.IconContainer {
    background-color: var(--color-dark-blue);
    border-radius: 100%;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top:-15px;
}

.Icon {
    font-size: 28px;
    color: var(--color-yellow);
    /* padding: 1rem; */
    font-weight: bold;
    margin: 0;
}

.serviceContainer {
    display: flex;
    flex-direction: column;
}

.serviceContainerTitle {
    font-weight: bold;
    color: var(--color-dark-blue);
    font-size: 22px;
    margin: 0;
    padding: 5px 0px;
}

.serviceContainerText {
    font-size: 14px;
    word-break: break-all;
    padding: 0rem 0rem;
    color: var(--color-light-gray)
}
/* @media screen and (max-width:600px){
    .OneText{
        padding: 0rem 1rem;
    }

    .container{
        padding: 0;
        margin: 0;
    }
} */

@media (min-width:600px) and (max-width:768px){
    .MidContainer,.Two{
        grid-template-columns:1fr 1fr;
        padding: 1.2rem;
    }

    .One{
        padding: 1rem;
       
    }

    .OneTitle{
        margin-left: 14px;
    }


}

@media (min-width:300px) and (max-width:600px){

    .RootIcon{
        font-size: 2.2rem;
    }
    
    .ServiceHeader{
        padding:0.5rem;
    }

   .ServiceHeaderTitle{
       font-size: 1.3rem;
   }

   .Two,.One{
       padding: 1rem;
   }
     .OneTitle{
         font-size: 20px;
         margin-left: 14px;
     }

     .OneText{
        font-size: 14px;
     }

     .IconContainer{
        padding : 0.8rem
     }

     .serviceContainerTitle{
         font-size: 14px;
     }

     .serviceContainerText{
        font-size: 12px;
     }
}

@media screen and (max-width:768px){
    .MidContainer,.Two{
        grid-template-columns: 1fr;
        padding: 1rem;
    }
}

@media screen and (max-width:992px){
    .OneText{
        padding: 0rem 1rem;
    }
    
    .container{
        padding: 0;
    }
}

@media (min-width:768px) and (max-width:992px){
    .One{
        padding: 1rem; 
    }

    .MidContainer{
        gap:20px;
    }

    .OneTitle{
        margin-left: 14px;
    }
    .MidContainer,.Two{
        grid-template-columns:1fr 1fr;
        padding: 1.2rem;
    }
}

@media (min-width:992px) and (max-width:1200px){
    .MidContainer{
        gap:20px;
    }
    .One{
        padding: 1rem; 
    }
    .OneTitle{
        margin-left: 14px;
    }
    .OneText{
        padding: 0rem 1rem;
    }
    .MidContainer,.Two{
        grid-template-columns:1fr 1fr;
        padding: 1.2rem;
    }
}

@media (min-width:1200px) and (max-width:130px) {
     .Two{
         padding: 0 4rem;
     }

    
}