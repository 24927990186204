.ContactHeader{
    display: flex;
    padding: 1.2rem 0rem;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);
    border-bottom: 2px solid white;
}

.NewContactContainer{
    display: flex;
    flex-direction: row;

}

.ContactHeaderTitle{
  
        color: var(--color-white);
        font-weight: bold;
        font-size: 1.8rem;
    
}

.container{
    padding: 2.8rem 10rem;
}

.Heading{
    color:var(--color-footer);
    font-size: 1.5rem;
    font-weight:bold;
    padding: 1rem 0rem;
}

.HeadingTwo{
    /* width: 100%; */
    color:var(--color-footer);
    font-size: 1.4rem;
    font-weight:bold;
    padding: 0.8rem 0rem;
}

.Para{
    font-size: 14px;
    color:var(--color-organization);
    letter-spacing: 1px;
}

.InputList{
    display: flex;
    flex-direction: column;
    gap:10px;
}
/* //////////////////////////////////////////////////////////// */
.backGroundImg{
    display:flex;
    width: 100%;
    height: 80vh;
    /* background-image: linear-gradient(red, yellow); */
    
    
}

.backGroundImg img{
    width:100%;
    height: 100%;
    opacity: 0.1;
}

.ContactUsMidContainer{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.ChatCenter{
    /* background-color: #dc1d13; */
    gap:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.SectionOne{
    /* background-color: blue; */
    flex: 1;
    gap:10px;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.SectionTwo{
    /* background-color: green; */
    flex: 1;
    gap:10px;
    min-height: 90vh;
    display: flex;
    padding-top: 60px;
    padding-right: 60px;
    /* justify-content: center; */
    /* padding: 0px 100px; */
    /* align-items: center; */
    flex-direction: column;
}

.Card{
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 20px 40px;
    border-radius: 10px;
    background-color: var(--color-white);
    min-height: 65vh;

}

.ChatHeading{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.ChatHeading h1{
    font-size: 50px;
    font-weight: bold;
}

.SubChatHeading h5{
    margin: 0;
    color: var(--color-organization);
}
.SectionOneIconSection{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.SectionOneIconSectionCard{
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    gap:20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;;
}

.SubChatHeading{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.IconContainer{
    background-color: var(--color-light-background);
    margin: 0;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.Icon{
    margin: 0;
    font-size: 20px;
}

.InfoContainer{
    display: flex;
    flex-direction: column;
}

.InfoHeadingOne{
    margin:0;
    font-size: 16px;
    font-weight: bold;
}
.InfoHeadingTwo{
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-footer);
}

@media (min-width:300px) and (max-width:600px){
    .ChatCenter{
        gap:6px;
    }
    .SectionOneIconSectionCard{
        padding: 10px;
    }
    .ChatHeading h1{
        font-size: 28px;
    }

    .InfoHeadingOne,.InfoHeadingTwo{
        font-size: 13px;
    }
    .SectionOne{
        min-height: 40vh;
    }

    .Card{}

    .SectionTwo{
        padding-top: 0px;
        padding-right: 0px;
        min-height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .Card{
        min-height: 45vh;
    }
    .backGroundImg{
        display:flex;
        width: 100%;
        height: 100vh;
        /* background-image: linear-gradient(red, yellow); */  
    }
    
    .backGroundImg img{
        width:100%;
        height: 100%;
        opacity: 0.1;
    }
    .ContactUsMidContainer{
        align-items: center;
        flex-direction: column;
    }
    .ContactHeader{
        padding: 0.5rem 0rem;
    }
    .Heading{
        padding: 0.5rem 0rem;
        font-size: 1rem;
    }

    .HeadingTwo{
        font-size: 1rem;
        padding: 0.4rem 0rem;
    }
    .container{
        padding: 0.5rem 1rem;
    }

    .InputList{
        width: 100%;
    }
}

@media (min-width:601px) and (max-width:768px){
    .ChatCenter{
        gap:6px;
    }
    .SectionOneIconSectionCard{
        padding: 10px;
    }
    .ChatHeading h1{
        font-size: 33px;
    }

    .InfoHeadingOne,.InfoHeadingTwo{
        font-size: 15px;
    }
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }
   
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }

    .Card{
        min-height: 45vh;
    }
    .backGroundImg{
        display:flex;
        width: 100%;
        height: 100vh;
        /* background-image: linear-gradient(red, yellow); */  
    }
    
    .backGroundImg img{
        width:100%;
        height: 100%;
        opacity: 0.1;
    }
    .ContactUsMidContainer{
        /* align-items: center; */
        flex-direction: column;
    }
    .container{
        padding: 1rem 1.7rem;
    }

    .InputList{
        width: 80%;
    }

}

@media (min-width:768px) and (max-width:992px){
    .ChatHeading h1{
        font-size: 28px;
    }

    .InfoHeadingOne,.InfoHeadingTwo{
        font-size: 13px;
    }
    .container{
        padding: 1rem 1.7rem;
    }

    .Card{
        min-height: 45vh;
    }
    .backGroundImg{
        min-height: 30vh;
    }
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }
   
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }

}


.ListContainer{
    display: flex;
    flex-direction: column;
}

.Error{
    margin: 0;
    font-size: 12px;
    color:#dc1d13;
    font-weight: 500;
}


@media (min-width:992px) and (max-width:1200px){
    .backGroundImg{
        min-height: 30vh;
    }
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }
   
    .SectionOne{
        width: 100%;
        min-height: 40vh;
    }
    .Card{
        min-height: 35vh;
    }
}

@media (min-width:1200px) and (max-width:130px) {
   
}