.quotesContainer{
    display: flex;
    flex-direction: row;
    padding: 4rem 10rem 2rem 10rem;

}

.QuoteIcon{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-bottom: 10px;
    margin-right: 10px;
    color: var(--color-light-gray);
}

.QuoteIconTwo{
    color: var(--color-light-gray);
}

.quotes{
    text-align: justify;
    font-family: "Tommy Black", Sans-serif;
    line-break: normal;
    font-weight: 100;
    font-size:  25px;
}

.CardContainer{
     padding:3rem 10rem;
}

.Card{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding:1rem 1rem 3rem 1rem;
    border-radius: 10px;
    background-color: #e9f5fb;
}

.Card label{
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 44px;
    padding: 1rem;
}

.Card p{
    text-align: justify;
    word-spacing: 3px;
    letter-spacing: 0.3px;
    padding: 0 3rem;
    line-break: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    font-size: 22px;
}

.CareerSection{
    display: flex;
    padding: 1rem 10rem;
    justify-content: flex-start;
    /* flex-direction: row; */
}
.CareerInfo{
    position: absolute;
   padding: 0rem 5rem;
   max-width: 500px;
    /* justify-content: flex-end; */
    background: rgba(252,59,0,0.8);
    opacity: 0.7;
    height: 80vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CareerheadingOne{
    color: white;
    font-size: 40px;
    font-weight: bolder;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}
.CareerheadingTwo{
    font-size: 23px;
    color: white;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}
.CareerheadingThree{
    font-size: 14px;
    color: white;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}

.CareerButton{
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CareerList{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.CareerImage{
    position: relative;
    background:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
        url(../../assets/career.jpg) no-repeat center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 80vh;
    background-size: cover; 
}

@media screen and (max-width:1200px) {
    .quotes{
        font-size: 22px;
        font-weight: 200;
    }
    .quotesContainer{
        padding: 3.2rem 3rem;
      
    }

    .CardContainer{
        padding:1rem;
   }
   
   .Card{

       padding:1rem 1rem 3rem 1rem;
    
   }
   
   .Card label{
       font-size: 35px;
   }
   
   .Card p{
      padding: 0rem 1rem;
      font-size: 18px;
   }
}

@media (min-width:768px) and (max-width:992px) {
    .quotes{
        font-size: 20px;
        font-weight: 200;
    }
    .quotesContainer{
        padding: 3.2rem 3rem;
      
    }

    .CardContainer{
        padding:1rem;
   }
   
   .Card{

       padding:1rem 1rem 3rem 1rem;
    
   }
   
   .Card label{
       font-size: 30px;
   }
   
   .Card p{
      padding: 0rem 1rem;
      font-size: 16px;
   }
}




@media (min-width:360px) and (max-width:768px) {
    .CareerSection{
        padding: 1rem;
    }
     .CareerInfo{
        padding: 0rem 1rem;
    }
    .quotes{
        font-size: 18px;
        font-weight: 200;
    }
    .quotesContainer{
        padding: 3.2rem 3rem;
      
    }

    .CardContainer{
        padding:1rem;
   }
   
   .Card{

       padding:1rem 1rem 3rem 1rem;
    
   }
   
   .Card label{
       font-size: 25px;
   }
   
   .Card p{
      padding: 0rem 1rem;
      font-size: 14px;
   }
}

@media  (min-width:0px) and (max-width:360px) {
    .CareerSection{
        padding: 1rem;
    }
     .CareerInfo{
        padding: 0rem 1rem;
    }

    .quotesContainer{
        padding: 1rem;
    }

    .quotes{
        font-size: 14px;
        font-weight: 200;
    }

    .CardContainer{
        padding:1rem;
   }
   
   .Card{

       padding:1rem 1rem 3rem 1rem;
    
   }
   
   .Card label{
       font-size: 25px;
   }
   
   .Card p{
      padding: 0rem 1rem;
      font-size: 14px;
   }
}