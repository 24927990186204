.container{
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: var(--color-background);
   padding: 0.8rem 2rem;
   /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
   
}

.navbarlogo{
    /* flex:1; */
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    /* width: 35px;
    height: 35px; */
    margin-left: 100px;
    transform: scale(6);
    
}

.navbarlogoimg{
    width: 100%;
    height: 100%;
   object-fit:contain;
}

.navbar_links{
    margin: 0;
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none !important;
}

.navbar_link{
     margin: 0 1rem;
     font-weight: 500;
     letter-spacing: 0.04em;
     text-transform: capitalize;
     color:var(--color-light-blue);
     line-height: 28px;
     font-size: 16px;
     cursor: pointer;
}

.navbar_link:hover{
    color: var(--color-gray);
    
}

.navbar_link_NotActive{
    text-decoration: none;
    color: var(--color-footer);
    padding: 5px 10px;
}

.navbar_link_Active_Small{
    
    color: var(--color-footer);
    padding: 5px 10px;
    border-radius: 5px;
}

.navbar_link_Active{
    background-color: var(--color-gray);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
}

.navbar_link a {
    text-decoration: none;
    padding: 5px 10px;
}
.navbar_link a:hover { 
    background-color: var(--color-gray);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
}

.navbar_link a:focus{
    background-color: var(--color-gray);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
 }
 .navbar_link a:visited { 
    text-decoration:none; 
    display:inline; margin:0 ;  
    font-size:11px;  
    font-weight:bold; 
}


 .navbar_link a:active{
    color: var(--color-white);
 }
 

.flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide_bottom{
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250,0.460,0.450,0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250,0.460,0.450,0.940) both;
}

@-webkit-keyframes slide-bottom {
    0%{
        -webkit-transform: translateY(-25%);
         transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}

@keyframes slide-bottom {
    0%{
        -webkit-transform: translateY(-25%);
         transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }
}

.navbar_smallscreen{
    display: none;
    
}

.navbar_smallscreen_overlay{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: var(--color-background);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_close{
    color: var(--color-black);
    font-size: 27px;
    cursor: pointer;
    position: absolute;
    top:20px;
    right:20px
}

.navbar_smallscreen_links{
    list-style: none;
    margin-top: -250px;
}

.navbar_smallscreen_links li{
    margin:2rem;
    cursor: pointer;
    font-size: 2rem;
    color:var(--color-light-blue);
    text-align: center;

}

.navbar_smallscreen_links li:hover{
    color:var(--color-gray)
}

@media screen and (max-width:1150px){
    .navbar_links{
        display: none;
    }

    .navbar_smallscreen{
        display: flex;
    }
}

@media screen and (max-width:650px) {
    .container{
        padding: 0.7rem 1rem;
    }

    .navbarlogo{
        width:30px;
    }
}

@media screen and (max-width:2000px){
    .navbarlogo{
        width: 40px;
        height: 40px;
    }
}