.AboutImageContainer{
    height: 60vh;
    width: 100%;
}

.AboutImage{
    position: relative;
    background:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
        url(../../assets/AboutUs.jpg) no-repeat center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover; 
}
.AboutTextContainer{
    width: 100%;
    height: 100%;
    padding: 0rem 0rem 0rem 10rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.AboutText{
    color: var(--color-white);
    font-weight: bold;
    font-size: 40px;
    margin: 0;
}

.AboutSubText{
    margin: 0;
    color: var(--color-white);
    font-weight: 500;
    font-size: 18px;
}
.SectionOneContainer{
    padding: 1rem 10rem;
}
.SectionOneHeadingOneContainer{
    display: flex;
    justify-content: center;
    align-items: center;
   padding: 0.5rem;
}

.SectionOneHeadingTwoContainer{
    display: flex;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
   
}

.SectionOneHeadingOne{
    margin: 0;
    font-weight: 500;
    font-size: 20px;

}
.SectionOneHeadingTwo{
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-gray);
}

.SectionTwoContainer{
    
    padding: 1rem 10rem;
}

.SectionSubTwo label{
   font-weight: 600;
   font-size: 18px;
   margin: 0;
}

.SectionSubTwo p{
    text-align: justify;
    margin: 0;
    padding: 8px 0;
    font-size: 14px;
}

.SectionThreeContainer{
    padding: 1rem 5rem;
    
}

.wrapperThree{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.CareerSection{
    display: flex;
    padding: 1rem 10rem;
    justify-content: flex-start;
    /* flex-direction: row; */
}
.CareerInfo{
    position: absolute;
   padding: 0rem 5rem;
   max-width: 500px;
    /* justify-content: flex-end; */
    background: rgba(252,59,0,0.8);
    opacity: 0.7;
    height: 80vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CareerheadingOne{
    color: white;
    font-size: 40px;
    font-weight: bolder;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}
.CareerheadingTwo{
    font-size: 23px;
    color: white;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}
.CareerheadingThree{
    font-size: 14px;
    color: white;
    margin: 0;
    font-family: "Tommy Black", Sans-serif;
}

.CareerButton{
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CareerList{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.CareerImage{
    position: relative;
    background:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
        url(../../assets/career.jpg) no-repeat center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 80vh;
    background-size: cover; 
}


@media (min-width:300px) and (max-width:600px){
    .AboutImageContainer{
        height: 40vh;
    }
    .CareerSection{
        padding: 1rem;
    }
     .CareerInfo{
        padding: 0rem 1rem;
    }
    .SectionTwoContainer,.SectionOneContainer{
        padding: 1rem;
    }
    .AboutTextContainer{
        padding: 1rem;
    }
    .AboutText{
        font-size: 24px;
    }
    .AboutSubText{
        font-size: 14px;
    }
   
}

@media (min-width:600px) and (max-width:768px){
   
}
@media (min-width:768px) and (max-width:992px){
  
}

@media (min-width:992px) and (max-width:1200px){

} 