.container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1.5rem 2rem;
    gap:15px;
    background-color: var(--color-light-background);
}

.OneTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--color-dark-blue)
}

.OneText {
    text-align: justify;
    line-break: normal;
    padding: 0rem 10rem;
    color: var(--color-organization);
    font-size: 18px;
    letter-spacing: 0.5px;
}

.Two{
    display: flex;
    flex-direction: row;
    padding: 0 10rem;
    gap:10px
}

.about{
    flex:1;
}

.ImgContainer{
    flex:1;
    height: 50vh;
    border-radius: 3px;
    overflow: hidden;
}

.ImgContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aboutText{
    line-break: normal;
    font-size: 16px;
    color:var(--color-organization);
    letter-spacing: 1px;
    text-align: justify;
}
/* //box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */

.list{
    display: flex;
    flex-direction: row;
    gap:7px;
    align-items: center;
}

.listIcon{
    font-size: 25px;
    color: var(--color-gray)
}

.listText{
    margin: 0px;
    color:var(--color-organization);
}

.listContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
    padding: 10px 0px;
}

.buttonContainer{
    padding: 1rem 0rem;
}

.Button{
    outline:none;
    border: none;
    padding: 0.8rem 1.5rem;
    background-color: var(--color-yellow);
    color:var(--color-dark-blue);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    
}

@media (min-width:300px) and (max-width:600px){
    .container{
        gap:0px;
    }

    .ImgContainer{
        margin-top: -1rem;
    }
    .OneTitle{
        font-size: 20px;
    }

    .OneText{
        font-size: 14px;
    }
   .Two{
       flex-direction: column;
   }
    .listIcon{
        font-size: 16px;
    }
   .aboutText{
    font-size: 12px;
   }
   .listText{
    font-size: 12px;
    margin-top: 0px;
   }

   .buttonContainer{
    padding: 0.7rem 0rem;
}

.Button{
    padding: 0.5rem 1.1rem;
    font-size: 12px;
}
}

@media screen and (max-width:768px){
    .Two{
        flex-direction: column-reverse;
    }
}
@media screen and (max-width:992px){
  
   .OneText,.Two{
       padding: 1rem;
   }
   .container{
       padding: 1rem;
   }
}

 @media (min-width:992px) and (max-width:1200px){

    .OneText,.Two{
        padding: 1rem;
    }
    .container{
        padding: 1.2rem 2rem;
    }
} 