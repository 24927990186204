.Container {
    display: flex;
}

.ImagOneContainer{
    position: relative;
    background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
    url(../../assets/HomeBg3.jpg) no-repeat center center;

    background-repeat: no-repeat;
    width: 100%;
    height: 70vh;
    background-size: cover; 
}

.ImagContainer {
    position: relative;
    background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
    url(../../assets/HomeBg1.jpg) no-repeat center center;
   

    background-repeat: no-repeat;
    width: 100%;
    height: 70vh;
    background-size: cover;

}

.ImagTwoContainer{
    position: relative;
    background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(8, 0, 6, 0.73)),
    url(../../assets/HomeBg2.jpg) no-repeat center center;

    background-repeat: no-repeat;
    width: 100%;
    height: 70vh;
    background-size: cover;
}

.headingOneData{
    color: white;
    font-size: 60px;
    margin: 0;
    font-weight: bold;
    font-family: "Tommy Black", Sans-serif;
}

.headingTwoData{
    color: white;
    font-size: 30px;
    margin: 0;
}

.headingOne {
   
    color: white;
    font-size: 70px;
    margin: 0;
    font-weight: bold;
    font-family: "Tommy Black", Sans-serif;
}

.headingTwo {
    color: white;
    font-size: 30px;
    margin: 0;
}

.AboveImage {
    width: 100%;
    height: 60vh;
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



@media (min-width:992px)and (max-width:1200px) {
    .headingOneData,.headingOne{
        font-size: 40px;
    }
    .headingTwoData,.headingTwo {
        font-size: 30px;  
    }
    .ImagOneContainer,.ImagContainer {
        height:50vh
    }
    .ImagTwoContainer {
        height:50vh
    }

    .AboveImage{
        height:50vh
    }
}

@media (min-width:768px) and (max-width:992px) {
    .headingOneData,.headingOne{
        font-size: 30px;
    }
    .headingTwoData,.headingTwo {
        font-size: 20px;  
    }
    .ImagOneContainer,.ImagContainer {
        height:40vh
    }
    .ImagTwoContainer {
        height:40vh
    }

    .AboveImage{
        height:40vh
    }
}

@media (min-width:360px) and (max-width:600px) {
    .headingOneData
    {  
        font-size: 18px;
    }
    .headingOne{
        font-size: 20px;
    }
    .headingTwoData,.headingTwo {
        font-size: 10px;  
    }
    .ImagOneContainer,.ImagContainer {
        height:30vh
    }
    .ImagTwoContainer {
        height:30vh
    }

    .AboveImage{
        height:30vh
    }
}

@media (min-width:600px) and (max-width:768px) {
    .headingOneData,.headingOne{
        font-size: 20px;
    }
    .headingTwoData,.headingTwo {
        font-size: 10px;  
    }
    .ImagOneContainer,.ImagContainer {
        height:30vh
    }
    .ImagTwoContainer {
        height:30vh
    }

    .AboveImage{
        height:30vh
    }
}

@media  (min-width:0px) and (max-width:360px) {
    .headingOneData{
        
        font-size: 14px;
    }
    .headingOne{
        font-size: 20px;
    }
    .headingTwoData,.headingTwo {
        font-size: 9px;  
    }
    .ImagOneContainer,.ImagContainer {
        height:30vh
    }
    .ImagTwoContainer {
        height:30vh
    }

    .AboveImage{
        height:30vh
    }
}